import { Form, Input, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { EditBtn } from '../../assets/styles/Styled';
import styled from 'styled-components';
import { ChangeVersionFormLabel } from '../../components/Antd/FormLabel';
import { ChangeWebAppVersionsFormValues, IWebConfigN } from '../../types';
import { isEqual } from 'lodash';

interface IChangeWebAppVersionFormProps {
  builds_download_urls: IWebConfigN;
  goiteens_builds_download_urls: IWebConfigN;
  updateWebAppConfig: ({
    configId,
    newConfig
  }: {
    configId: 'builds_download_urls' | 'goiteens_builds_download_urls';
    newConfig: ChangeWebAppVersionsFormValues;
  }) => void;
}

interface IFormWebApp {
  builds_download_urls: IWebConfigN;
}
interface IFormGoITeens {
  goiteens_builds_download_urls: IWebConfigN;
}

const Container = styled.div`
  width: 100%;
`;
export const ChangeWebAppVersionForm: React.FC<IChangeWebAppVersionFormProps> = ({
  builds_download_urls,
  goiteens_builds_download_urls,
  updateWebAppConfig
}) => {
  const [isDisabledWebApp, setIsDisabledWebApp] = useState<boolean>(true);
  const [isDisabledGoITeens, setIsDisabledGoITeens] = useState<boolean>(true);

  const [webAppForm] = Form.useForm<IFormWebApp>();
  const [goiteensForm] = Form.useForm<IFormGoITeens>();

  const initialValuesWebApp = { builds_download_urls };
  const initialValuesGoIteens = { goiteens_builds_download_urls };

  const formWebAppWatch = Form.useWatch('builds_download_urls', webAppForm);
  const formGoiteensFormWatch = Form.useWatch('goiteens_builds_download_urls', goiteensForm);

  useEffect(() => {
    webAppForm.setFieldsValue({ builds_download_urls: builds_download_urls });
    goiteensForm.setFieldsValue({ goiteens_builds_download_urls: goiteens_builds_download_urls });
  }, [builds_download_urls, goiteens_builds_download_urls]);

  useEffect(() => {
    const isDisabled = isEqual(builds_download_urls, formWebAppWatch);
    setIsDisabledWebApp(isDisabled);
  }, [formWebAppWatch]);

  useEffect(() => {
    const isDisabled = isEqual(goiteens_builds_download_urls, formGoiteensFormWatch);
    setIsDisabledGoITeens(isDisabled);
  }, [formGoiteensFormWatch]);

  const validateFormat = (_: any, value: string) => {
    const regex = /^\d+\.\d+\.\d+$/;
    if (!regex.test(value)) {
      return Promise.reject(new Error('Version must be in the format X.X.X'));
    }
    return Promise.resolve();
  };

  const handleSubmitWebAppConfig = (values: IFormWebApp) => {
    updateWebAppConfig({
      configId: 'builds_download_urls',
      newConfig: values
    });
    setIsDisabledWebApp(true);
  };

  const handleSubmitGoiteens = (values: IFormGoITeens) => {
    updateWebAppConfig({
      configId: 'goiteens_builds_download_urls',
      newConfig: values
    });
    setIsDisabledGoITeens(true);
  };

  const formStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: '28px'
  };
  return (
    <Container>
      <Form
        name="basic"
        className="w-100"
        layout="vertical"
        initialValues={initialValuesWebApp}
        style={formStyle}
        form={webAppForm}
        onFinish={handleSubmitWebAppConfig}
        requiredMark={false}
      >
        <Form.Item
          label={<ChangeVersionFormLabel text="Update url windows" />}
          name={['builds_download_urls', 'windows']}
          rules={[{ required: true, message: 'Please Enter new url' }]}
          style={{ marginBottom: '0', maxWidth: '600px', width: '100%' }}
          validateTrigger="onSubmit"
        >
          <Input type="text" placeholder="Update url windows" size="large" />
        </Form.Item>
        <Form.Item
          label={<ChangeVersionFormLabel text="Update url macos" />}
          name={['builds_download_urls', 'macos']}
          rules={[{ required: true, message: 'Please Enter new url' }]}
          style={{ marginBottom: '0', maxWidth: '600px', width: '100%' }}
          validateTrigger="onSubmit"
        >
          <Input type="text" placeholder="Update url macos" size="large" />
        </Form.Item>
        <Form.Item
          label={<ChangeVersionFormLabel text="Update client version" />}
          name={['builds_download_urls', 'client_version']}
          rules={[{ required: true, message: 'Please Enter new version' }, { validator: validateFormat }]}
          style={{ marginBottom: '0', maxWidth: '600px', width: '100%' }}
          validateTrigger="onSubmit"
        >
          <Input type="text" placeholder="Update client version" size="large" />
        </Form.Item>
        <EditBtn className="btn-orange w-100  mr-0 mt-0 mb-4" type="submit" disabled={isDisabledWebApp}>
          Apply
        </EditBtn>
      </Form>
      <Form
        name="basic"
        className="w-100"
        layout="vertical"
        initialValues={initialValuesGoIteens}
        style={formStyle}
        form={goiteensForm}
        onFinish={handleSubmitGoiteens}
        requiredMark={false}
      >
        <Typography className="bold-24 text-basic">Go-iteens Config</Typography>
        <Form.Item
          label={<ChangeVersionFormLabel text="Update url windows" />}
          name={['goiteens_builds_download_urls', 'windows']}
          rules={[{ required: true, message: 'Please Enter new url' }]}
          style={{ marginBottom: '0', maxWidth: '600px', width: '100%' }}
          validateTrigger="onSubmit"
        >
          <Input type="text" placeholder="Update url windows" size="large" />
        </Form.Item>
        <Form.Item
          label={<ChangeVersionFormLabel text="Update url macos" />}
          name={['goiteens_builds_download_urls', 'macos']}
          rules={[{ required: true, message: 'Please Enter new url' }]}
          style={{ marginBottom: '0', maxWidth: '600px', width: '100%' }}
          validateTrigger="onSubmit"
        >
          <Input type="text" placeholder="Update url macos" size="large" />
        </Form.Item>
        <Form.Item
          label={<ChangeVersionFormLabel text="Update client version" />}
          name={['goiteens_builds_download_urls', 'client_version']}
          rules={[{ required: true, message: 'Please Enter new version' }, { validator: validateFormat }]}
          style={{ marginBottom: '0', maxWidth: '600px', width: '100%' }}
          validateTrigger="onSubmit"
        >
          <Input type="text" placeholder="Update client version" size="large" />
        </Form.Item>
        <EditBtn className="btn-orange w-100 mt-0 mr-0 mb-4" type="submit" disabled={isDisabledGoITeens}>
          Apply
        </EditBtn>
      </Form>
    </Container>
  );
};
