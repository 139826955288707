import React from 'react';
import { IDeleteResource, IMoveScriptsData, OrganizationItem, Resource } from '../../types';
import { FileIcon, FolderIcon } from '../../assets/icons';
import { Spin, Tag } from 'antd';
import styled from 'styled-components';
import { DropDown } from './DropDown';

const heightHeader = 220;
const bodyHeight = 298;

const StyledItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100vh - ${heightHeader}px - ${bodyHeight}px);
  width: 100%;
  overflow-y: auto;
`;

const StyledItem = styled.div`
  background: var(--text-white);
  border-radius: 16px;
  box-shadow: 0px 3.45px 13.79px 0px rgba(0, 0, 0, 0.07);

  &.item-wrapper:last-of-type {
    margin-bottom: 20px;
  }
`;

const StyledItemBody = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 16px;
  &:last-child {
    border-bottom: none;
  }
`;

const StyledItemTitle = styled.div`
  max-width: 115px;
  min-width: 83px;
  width: 100%;
  font-size: 14px;
  font-weight: 800;
  line-height: 22px;
  color: var(--light-description);
  word-break: break-all;
`;
const StyledItemContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 800;
  line-height: 20.79px;
  text-align: center;
  color: var(--primary-text);
  position: relative;
  &.ellipsisRow {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
  }
`;

const DropdownWrapper = styled.div``;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const StyledTag = styled(Tag)`
  margin: 0;
  ${props => (!props.color ? 'color: var(--primary-color)' : null)};
  ${props => (!props.color ? 'background-color: var(--light-violet)' : null)};
  font-size: 14px;
  font-weight: 400;
  height: 22px;
  line-height: 18px;
  padding: 1px 8px;
  border-radius: 24px;
`;

interface Props {
  resourse: Resource[];
  loading: boolean;
  onRemove?: (data: IDeleteResource) => void;
  fetchScripts?: () => Promise<void>;
  handleRowDoubleClick?: (record: Resource) => void;
  handleMoveScripts: ({ folderId, scripts }: IMoveScriptsData) => Promise<void>;
}

export const ScriptItemMobileView: React.FC<Props> = ({
  resourse,
  loading,
  fetchScripts,
  handleRowDoubleClick,
  onRemove,
  handleMoveScripts
}) => {
  const renderItem = (rowName: string, content: React.ReactNode | string, resourse?: Resource) => {
    const isEllipsisRow = rowName.toLowerCase() === 'name';
    return (
      <StyledItemBody>
        <StyledItemTitle>{rowName}</StyledItemTitle>
        {
          <StyledItemContent className={`${isEllipsisRow ? 'ellipsisRow' : ''}`}>
            {resourse ? (
              <>
                <StyledItemContent>{content}</StyledItemContent>
                <DropdownWrapper onClick={e => e.stopPropagation()}>
                  <DropDown
                    resourse={resourse}
                    onRemove={onRemove}
                    fetchScripts={fetchScripts}
                    handleMoveScripts={handleMoveScripts}
                  />
                </DropdownWrapper>
              </>
            ) : (
              content
            )}
          </StyledItemContent>
        }
      </StyledItemBody>
    );
  };

  if (loading) {
    return <Spin spinning={loading} className="w-100"></Spin>;
  }
  const handleOpenFolder = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, script: Resource) => {
    // if ((!target.closest('.drop-munu') && !target.closest('.ant-dropdown')) && handleRowDoubleClick) {
    handleRowDoubleClick && handleRowDoubleClick(script);
    // }
  };
  return (
    // <Spin spinning={loading}>
    <StyledItemWrapper>
      {resourse.map(resourse => (
        <StyledItem key={resourse.resource_id} onClick={e => handleOpenFolder(e, resourse)} className="item-wrapper">
          <>
            {renderItem(
              'Type',
              resourse.resource_type === 'folder' ? (
                <FolderIcon />
              ) : (
                <FileIcon style={{ width: '18px', height: '22px', verticalAlign: 'inherit' }} className="hint-color" />
              )
            )}
          </>
          <>{renderItem('Name', resourse.resource_name)}</>
          <>{renderItem('Creator', 'creator@gmail.com')}</>
          <>{renderItem('Organization', <OrganizationTags organizations={resourse.organization_names || []} />, resourse)}</>
        </StyledItem>
      ))}
    </StyledItemWrapper>
    // </Spin>
  );
};

const OrganizationTags: React.FunctionComponent<{ organizations: string[] }> = ({ organizations }) => {
  if (!organizations?.length) {
    return <StyledTag color="blue">None</StyledTag>;
  }

  return (
    <TagContainer>
      {organizations.map((org, idx) => (
        <StyledTag key={`${org}-${idx}`} bordered={false}>
          {org || 'N/A'}
        </StyledTag>
      ))}
    </TagContainer>
  );
};
