import React, { useState } from 'react';
import { Form, Space, Spin, Typography } from 'antd';
import CloseIcon from '../../../assets/close.png';
import { LoadingOutlined } from '@ant-design/icons';
import { PopUpWrapper, StyledPopUpContent, StyledPopupHeader } from '../ScriptStyles';
import { IDeleteResource, Resource, ResourceDetail } from '../../../types';
import styled from 'styled-components';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { EditBtn } from '../../../assets/styles/Styled';

const PopUpContent = styled(StyledPopUpContent)`
  max-width: 330px;
`;

const Text = styled.p`
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  width: 100%;
  & > span {
    font-weight: 700;
    display: inline-block;
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: sub;
  }
`;

const TextDelete = styled.p`
  font-family: 'Mulish', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--text-red);
`;

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: var(--text-red) !important;
    color: var(--text-red) !important;

    &::after {
      border-color: var(--text-red) !important;
      border-spacing: 0;
    }
  }
`;

const ButtonDelete = styled(EditBtn)`
  background: rgba(239, 90, 85, 0.2);
  color: var(--text-red);

  &:hover {
    background: var(--text-red);
  }

  &:disabled {
    pointer-events: none;
  }
`;

interface IPopupDeleteFolderProps {
  folder: Resource | ResourceDetail;
  setShowPopupDeleteFolder: React.Dispatch<React.SetStateAction<boolean | null>>;
  onRemove?: (data: IDeleteResource) => void;
}

interface DeleteForm {
  deleteAllChildrens: boolean;
}

const PopupDeliteFolder: React.FC<IPopupDeleteFolderProps> = ({ setShowPopupDeleteFolder, onRemove, folder }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisableButtonsSubmit, setIsDisableButtonSubmit] = useState(false);
  const [form] = Form.useForm<DeleteForm>();

  const { resource_id, resource_name, resource_type } = folder;

  const handleSubmit = async (value: DeleteForm) => {
    setIsLoading(true);
    setIsDisableButtonSubmit(true);

    if (!resource_id) return;
    onRemove &&
      onRemove({
        resourceId: resource_id,
        resourceType: resource_type,
        deleteChildResources: value.deleteAllChildrens
      });
    setShowPopupDeleteFolder(false);
    setIsLoading(false);
    setIsDisableButtonSubmit(false);
  };

  return (
    <PopUpWrapper>
      <PopUpContent>
        <StyledPopupHeader>
          <Typography.Text className="text-title fnt-open-sans" style={{ fontSize: '24px', fontWeight: '700' }}>
            Are you sure?
          </Typography.Text>
          <div className="close" onClick={() => setShowPopupDeleteFolder(null)}>
            <img src={CloseIcon} alt={''} />
          </div>
        </StyledPopupHeader>
        <Text>
          Please, confirm deleting <span>{resource_name}</span> folder.
        </Text>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          disabled={isLoading}
          initialValues={{ deleteAllChildrens: false }}
          style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}
        >
          <Form.Item
            style={{ width: '100%', marginBottom: '0', textAlign: 'left' }}
            name="deleteAllChildrens"
            valuePropName="checked"
          >
            <StyledCheckbox defaultChecked={false}>
              <TextDelete>Delete also all my scripts that were stored in this folder</TextDelete>
            </StyledCheckbox>
          </Form.Item>

          <ButtonDelete type="submit" disabled={isLoading}>
            <Space>
              {' '}
              Delete Folder
              <Spin spinning={isLoading} indicator={<LoadingOutlined spin />} size="small" />
            </Space>
          </ButtonDelete>
        </Form>
      </PopUpContent>
    </PopUpWrapper>
  );
};

export default PopupDeliteFolder;
