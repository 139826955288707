import React, { useEffect, useMemo, useState } from 'react';
import { CreateAccountBtnWrapper, PageContainer, PageContent, PageWrapper, StyledButton } from '../../assets/styles/Styled';
import { Link } from 'react-router-dom';
import { message, Space, Typography } from 'antd';
import { deleteResource, getResource, getResources, moveScripstToFolder } from '../../api/apiWorker';
import {
  FilterOptions,
  IMoveScriptsData,
  IMoveScriptToFolderError,
  Resource,
  SortOptions,
  IDeleteResource,
  ResourceDetail
} from '../../types';
import { Search } from '../../components/Search';
import { FilterItems, FilterPanel } from './FilterPanel';
import { ScriptsTable } from './ScriptsTable';
import { extractOrganizationsFromScripts } from '../../helper';
import {
  AddScriptIcon,
  AllFilesIcon,
  BackArrowIcon,
  BasketIcon,
  FolderAddIcon,
  FolderIcon,
  ResentIcon,
  ShareIcon
} from '../../assets/icons';
import styled from 'styled-components';
import { PopupCreateFolder } from './popups/PopupCreateFolder';
import { ScriptItemMobileView } from './ScriptItemMobileView';
import { PopupSelectScriptOrFolder } from './popups/PopupSelectScriptOrFolder';
import { PopupSharedFolder } from './popups/PopupSharedFolder';
import { FilterPanelItems } from './scriptPage.styled';
import { useNavigate, useLocation } from 'react-router-dom';
import PopupDeliteFolder from './popups/PopupDeliteFolder';
import { ButtonAddScriptToFolder, EmptyStateContainer } from './ScriptStyles';
import { PopupErrorAccessSettings } from './popups/PopupErrorAccessSettings';
export interface Filters {
  search: string;
  filter: FilterItems;
}

export const FilterPanelWrapper = styled.div`
  display: flex;
  gap: 0 12px;
  align-items: center;
  padding: 21px 0;

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 12px;
  }

  .folder-icon {
    > svg {
      width: 18px;
      height: 18px;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .filter-panel {
    margin-top: 0;
    margin-bottom: 0;
    width: max-content;

    @media (max-width: 450px) {
      margin-right: auto;
    }

    .btn-filter {
      .btn-filter-text {
        font-size: 14px !important;
        width: max-content;
      }
    }
  }
`;

const BreadCrumbs = styled.div<{ isActiveSearch: boolean }>`
  font-size: 24px;
  font-weight: 800;
  line-height: 22px;
  color: var(--primary-text);
  width: ${({ isActiveSearch }) => (isActiveSearch ? '100px' : '100%')};
  max-width: ${({ isActiveSearch }) => (isActiveSearch ? '100px' : '400px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 768px) {
    max-width: ${({ isActiveSearch }) => (isActiveSearch ? '100px' : '303px')};
  }
  @media (max-width: 450px) {
    min-width: unset;
    max-width: unset;
    width: 100%;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  background: var(--text-disabled);
  border-radius: 17px;
  margin-bottom: 18px;
  margin-top: -24px;
`;
const Tab = styled.div`
  font-family: 'Mulish', sans-serif !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 8px;
  gap: 8px;
  color: var(--light-description);
  cursor: pointer;

  &.disabled {
    pointer-events: none;
  }

  span {
    svg {
      path {
        fill: var(--light-description);
      }
    }
  }

  &.active {
    font-weight: 800;

    background: #fff;
    box-shadow: 0px 3.45px 13.79px 0px rgba(0, 0, 0, 0.07);

    border-radius: 13px;
    color: var(--description-solid);
    span {
      svg {
        path {
          fill: var(--description-solid);
        }
      }
    }
  }
`;

export type Tabs = 'resents' | 'allFiles' | 'folders';

const ScriptsPage = () => {
  const [loading, setLoading] = useState(false);
  const [scripts, setScripts] = useState<Resource[]>([]);
  const [scriptOrganizations, setScriptOrganizations] = useState<string[]>([]);
  const [requestDataMoveFiles, setRequestDataMoveFiles] = useState<IMoveScriptsData | null>(null);
  const [showPopupCreateFolder, setShowPopupCreateFolder] = useState<boolean | null>(null);
  const [showPopupSelectScript, setShowPopupSelectScript] = useState<boolean | null>(null);
  const [showPopupSharedFolder, setShowPopupSharedFolder] = useState<boolean | null>(null);
  const [showPopupDeleteFolder, setShowPopupDeleteFolder] = useState<boolean | null>(null);
  const [showPopupErrorAccessSettings, setShowPopupErrorAccessSettings] = useState<boolean | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const [selectedFolder, setSelectedFolder] = useState<ResourceDetail | null>(null);
  const [activeTab, setActiveTab] = useState<Tabs>('allFiles');

  const [filters, setFilters] = useState<Filters>({
    search: '',
    filter: { option: FilterOptions.all, selectedOrganizations: null }
  });
  const [sortOption, setSortOption] = useState<SortOptions>(SortOptions.newest);
  const [toggleSearch, setToggleSearch] = useState(false);

  const handleSetActiveTab = (tab: Tabs) => {
    setActiveTab(tab);
  };

  const fetchResources = async () => {
    const params = new URLSearchParams(location.search);
    const folderId = params.get('folderId');
    setLoading(true);

    try {
      let queryParam = '';
      if (activeTab === 'folders') {
        queryParam = '?resource_type=folder';
      } else if (folderId) {
        queryParam = `?parent_id=${folderId}`;
      }

      const data = queryParam ? await getResources(queryParam) : await getResources();
      if (Array.isArray(data)) {
        setScripts(data);
        const organizationList = extractOrganizationsFromScripts(data);
        setScriptOrganizations(organizationList);
      } else {
        setScripts([]);
      }
    } catch (e) {
      message.error('Error fetching scripts');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResources();
  }, [activeTab, location.search]);

  const handleSetCurrentFolder = async (folderId: string) => {
    const folder = await getResource(folderId, `?parent_id=${folderId}`);
    setSelectedFolder(folder);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const folderId = params.get('folderId');
    if (folderId) {
      handleSetCurrentFolder(folderId);
      return;
    }

    setSelectedFolder(null);
  }, [location.search]);

  const filteredScripts: Resource[] = useMemo(() => {
    const folders = scripts.filter(script => script.resource_type === 'folder');
    const scriptFiles = scripts.filter(script => script.resource_type === 'script');

    let resultFolder = folders.filter(script => script.resource_name.toLowerCase().includes(filters.search.toLowerCase()));
    let resultScripts = scriptFiles.filter(script => script.resource_name.toLowerCase().includes(filters.search.toLowerCase()));

    const { option, selectedOrganizations } = filters.filter;
    if (option === FilterOptions.withoutOrganization) {
      resultFolder = folders.filter(folder => !folder.organization_names?.length);
      resultScripts = scriptFiles.filter(script => !script.organization_names?.length);
    }
    if (selectedOrganizations?.length) {
      resultFolder = resultFolder.filter(folder => {
        return folder.organization_names?.some(org => selectedOrganizations.includes(org));
      });
      resultScripts = resultScripts.filter(script => {
        return script.organization_names?.some(org => selectedOrganizations.includes(org));
      });
    }

    // switch (sortOption) {
    //   case SortOptions.newest:
    //     resultFolder.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    //     resultScripts.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    //     break;
    //   case SortOptions.eldest:
    //     resultFolder.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
    //     resultScripts.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

    //     break;
    //   case SortOptions.az:
    //     resultFolder.sort((a, b) => a.resource_name.localeCompare(b.resource_name));
    //     resultScripts.sort((a, b) => a.resource_name.localeCompare(b.resource_name));

    //     break;
    //   case SortOptions.za:
    //     resultFolder.sort((a, b) => b.resource_name.localeCompare(a.resource_name));
    //     resultScripts.sort((a, b) => b.resource_name.localeCompare(a.resource_name));
    //     break;
    //   default:
    //     break;
    // }

    return [...resultFolder, ...resultScripts];
  }, [scripts, filters, sortOption]);

  const handleRemove = async ({ resourceId, resourceType, deleteChildResources }: IDeleteResource) => {
    setLoading(true);
    try {
      await deleteResource({
        resourceId: resourceId,
        ...(resourceType === 'folder' && { deleteChildResources: deleteChildResources || false }),
        resourceType: resourceType
      });

      if (deleteChildResources !== undefined && selectedFolder) {
        handleBackToAllScripts();
      } else {
        await fetchResources();
      }

      message.success('Script was deleted successfully');
    } catch (e) {
      message.error('Unable to delete script');
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteFolder = () => {
    setShowPopupDeleteFolder(true);
  };

  const handleCreateFolder = () => {
    setShowPopupCreateFolder(true);
  };

  const handleRowDoubleClick = (record: Resource) => {
    if (record.resource_type === 'folder') {
      const folderId = record.resource_id;
      navigate(`?folderId=${folderId}`);
      setActiveTab('allFiles');
    }
  };

  const handleBackToAllScripts = () => {
    navigate('', { replace: true });
    setActiveTab('allFiles');
  };

  const handleAddScriptsToFolder = () => {
    setShowPopupSelectScript(true);
  };

  const handleMoveScripts = async ({ folderId, scripts, force_parent_access }: IMoveScriptsData) => {
    setLoading(true);
    try {
      await moveScripstToFolder({ folderId, scripts, ...(force_parent_access ? { force_parent_access: true } : {}) });
      await fetchResources();
      setRequestDataMoveFiles(null);
    } catch (e) {
      const error: IMoveScriptToFolderError = e as IMoveScriptToFolderError;

      if (error.response?.data?.code === 'resource_access_does_not_match') {
        setRequestDataMoveFiles({
          folderId: folderId,
          scripts: scripts
        });
        setShowPopupErrorAccessSettings(true);
      } else {
        message.error('Something went wrong. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAggreeMoveScripts = () => {
    if (!requestDataMoveFiles) return;
    handleMoveScripts({ ...requestDataMoveFiles, force_parent_access: true });
  };

  return (
    <PageWrapper>
      <PageContainer>
        <PageContent>
          {showPopupCreateFolder && (
            <PopupCreateFolder setShowPopupCreateFolder={setShowPopupCreateFolder} setActiveTab={setActiveTab} />
          )}
          {showPopupDeleteFolder && selectedFolder && (
            <PopupDeliteFolder
              folder={selectedFolder}
              setShowPopupDeleteFolder={setShowPopupDeleteFolder}
              onRemove={handleRemove}
            />
          )}
          {showPopupSelectScript && (
            <PopupSelectScriptOrFolder
              resourceType="script"
              selectedResourceId={selectedFolder?.resource_id || ''}
              setShowPopupSelectScript={setShowPopupSelectScript}
              handleMoveScripts={handleMoveScripts}
            />
          )}
          {showPopupSharedFolder && selectedFolder && (
            <PopupSharedFolder setShowPopupSharedFolder={setShowPopupSharedFolder} resourceId={selectedFolder.resource_id} />
          )}
          {showPopupErrorAccessSettings && (
            <PopupErrorAccessSettings
              handleAggreeMoveScripts={handleAggreeMoveScripts}
              setRequestDataMoveFiles={setRequestDataMoveFiles}
              setShowPopupErrorAccessSettings={setShowPopupErrorAccessSettings}
            />
          )}
          <Space direction="vertical" size={24} className="w-100">
            <CreateAccountBtnWrapper className="btn-script">
              <Link to={selectedFolder?.resource_id ? `/scripts/new?parentId=${selectedFolder.resource_id}` : '/scripts/new'}>
                <StyledButton className="btn-green">Create Script</StyledButton>
              </Link>
            </CreateAccountBtnWrapper>
            <FilterPanelWrapper>
              <BreadCrumbs isActiveSearch={toggleSearch}>
                <span
                  onClick={selectedFolder?.resource_id ? handleBackToAllScripts : () => undefined}
                  className={selectedFolder?.resource_id ? 'cursor-pointer' : 'cursor-default'}
                >
                  {selectedFolder?.resource_id && <BackArrowIcon />}

                  {!selectedFolder?.resource_id && (
                    <span className={`${selectedFolder?.resource_name ? 'light-description' : 'primary-text'}`}>
                      Available Scripts {selectedFolder?.resource_name && '/'}{' '}
                    </span>
                  )}
                </span>

                {selectedFolder?.resource_name && <span className="cursor-default">{selectedFolder?.resource_name}</span>}
              </BreadCrumbs>

              <FilterPanelItems>
                <FilterPanel
                  organizations={scriptOrganizations}
                  onSort={opt => setSortOption(opt)}
                  onFilterChange={filter => setFilters(prev => ({ ...prev, filter }))}
                  isShowSortPanel={false}
                />
                <Search
                  className={`${toggleSearch ? 'search-visible' : 'search-hide'}`}
                  placeholder=""
                  onChange={search => setFilters(prev => ({ ...prev, search }))}
                  setToggleSearch={setToggleSearch}
                />
                {selectedFolder?.resource_id ? (
                  <>
                    <AddScriptIcon className="folder-icon" onClick={handleAddScriptsToFolder} />
                    <ShareIcon className="folder-icon" onClick={() => setShowPopupSharedFolder(true)} />
                    <BasketIcon className="folder-icon" onClick={handleDeleteFolder} />
                  </>
                ) : (
                  <FolderAddIcon className="folder-icon" onClick={handleCreateFolder} />
                )}
              </FilterPanelItems>
            </FilterPanelWrapper>
            <TabsWrapper>
              {/* <Tab className={activeTab === 'resents' ? 'active' : ''} onClick={() => handleSetActiveTab('resents')}>
                <ResentIcon />
                Resents
              </Tab> */}
              <Tab className={activeTab === 'allFiles' ? 'active' : ''} onClick={() => handleSetActiveTab('allFiles')}>
                <AllFilesIcon />
                All files
              </Tab>
              <Tab
                className={`${activeTab === 'folders' ? 'active' : ''} ${selectedFolder?.resource_id && 'disabled'}`}
                onClick={() => handleSetActiveTab('folders')}
              >
                <FolderIcon />
                Folders
              </Tab>
            </TabsWrapper>
          </Space>
          {selectedFolder && !!!filteredScripts.length ? (
            <>
              <div className="hide-lg">
                <ScriptsTable tableData={[]} loading={loading} isHideBody={true} />
              </div>

              <EmptyStateContainer>
                <Typography.Paragraph className="hint-color" style={{ fontSize: '14px', fontWeight: '800' }}>
                  This folder is empty. Let`s add something!
                </Typography.Paragraph>
                <ButtonAddScriptToFolder onClick={handleAddScriptsToFolder}>Move Files</ButtonAddScriptToFolder>
              </EmptyStateContainer>
            </>
          ) : (
            <>
              <div className="hide-lg">
                <ScriptsTable
                  tableData={filteredScripts}
                  loading={loading}
                  onRemove={handleRemove}
                  fetchScripts={fetchResources}
                  handleRowDoubleClick={handleRowDoubleClick}
                  handleMoveScripts={handleMoveScripts}
                />
              </div>
              <div className="hide-sm">
                <ScriptItemMobileView
                  resourse={filteredScripts}
                  loading={loading}
                  onRemove={handleRemove}
                  fetchScripts={fetchResources}
                  handleRowDoubleClick={handleRowDoubleClick}
                  handleMoveScripts={handleMoveScripts}
                />
              </div>
            </>
          )}
        </PageContent>
      </PageContainer>
    </PageWrapper>
  );
};

export default ScriptsPage;
