import React from 'react';
import CloseIcon from '../../../assets/close.png';
import { Space, Typography } from 'antd';

import { ButtonSubmit, PopUpWrapper, StyledPopUpContent, StyledPopupHeader } from '../ScriptStyles';
import { IMoveScriptsData } from '../../../types';
import styled from 'styled-components';

const PopUpContent = styled(StyledPopUpContent)`
  max-width: 445px;
  padding: 20px 14px;
`;

const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledText = styled.p`
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  line-height: 20.08px;
  font-weight: 400;
  color: var(--text-light);
`;

const Button = styled(ButtonSubmit)`
  width: 100%;
`;
const ButtonDecline = styled(ButtonSubmit)`
  width: 100%;

  border: 2px solid var(--orange-solid);
  color: var(--orange-solid);
  background: transparent;
  box-shadow: unset;
  margin-top: 8px;

  &:hover {
    background: transparent;
    color: var(--orange-solid);
  }
`;

interface Props {
  setShowPopupErrorAccessSettings: React.Dispatch<React.SetStateAction<boolean | null>>;
  setRequestDataMoveFiles: (value: React.SetStateAction<IMoveScriptsData | null>) => void;
  handleAggreeMoveScripts: () => void;
}

export const PopupErrorAccessSettings: React.FC<Props> = ({
  setShowPopupErrorAccessSettings,
  handleAggreeMoveScripts,
  setRequestDataMoveFiles
}) => {
  const handleDecline = () => {
    setRequestDataMoveFiles(null);
    setShowPopupErrorAccessSettings(null);
  };

  const handleAccess = () => {
    handleAggreeMoveScripts();
    setShowPopupErrorAccessSettings(null);
  };

  return (
    <PopUpWrapper>
      <PopUpContent>
        <StyledPopupHeader>
          <Typography.Text
            className="text-title fnt-open-sans"
            style={{ fontSize: '24px', fontWeight: '700', textAlign: 'left', paddingRight: '45px', lineHeight: '32px' }}
          >
            Oops! The access settings of some scripts require updating!
          </Typography.Text>

          <div className="close" onClick={handleDecline}>
            <img src={CloseIcon} alt={''} />
          </div>
        </StyledPopupHeader>
        <StyledText>
          Some scripts have different access type, after adding them to the folder, their sharing option may be updated, so all
          accounts can see the file (but still can`t edit).
        </StyledText>
        <ButtonsBlock>
          <Button type="button" onClick={handleAccess}>
            <Space>Allow This Script To Be Viewed In Folder</Space>
          </Button>
          <ButtonDecline type="button" onClick={handleDecline}>
            <Space>Leave Scripts Access As it Is</Space>
          </ButtonDecline>
        </ButtonsBlock>
      </PopUpContent>
    </PopUpWrapper>
  );
};
