import React, { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import TermsOfUsePrivacyPolicy from '../components/TermsOfUsePrivacyPolicy';
import { Button, Input, Label, Form, LoginWrapper, Logo, SsoButtons } from '../assets/styles/Styled';
import { useAuth } from '../context/AuthContext';
import { message, Spin } from 'antd';

import { CredentialResponse, GoogleLogin, googleLogout } from '@react-oauth/google';
import AppleSignInButton from '../components/AppleSignInButton';
import { Providers } from '../types';
import styled from 'styled-components';
const LoginForm = styled(Form)`
  margin-bottom: 16px;
`;

const LoginPage = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    try {
      await login(email, password);
      navigate('/scripts');
    } catch (error) {
      message.error('Login failed. Please check your credentials.');
      googleLogout();
      console.error('Failed to login after sso:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLoginWithSso = async (authToken: string, provider: Providers) => {
    if (authToken) {
      setLoading(true);
      try {
        await login('', '', authToken, provider);
        navigate('/scripts');
      } catch (error) {
        message.error('Login failed.');
        console.error('Failed to Sso login:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <LoginWrapper>
      <Logo />
      <p className="log-in">Admin Log in</p>
      <Spin spinning={loading} tip="Loading...">
        <LoginForm onSubmit={handleSubmit}>
          <Label htmlFor="email">Email</Label>
          <Input id="email" type="email" placeholder="Enter your email here" value={email} onChange={handleEmailChange} />
          <Label htmlFor="password">Password</Label>
          <Input
            id="password"
            type="password"
            placeholder="Enter your password here"
            value={password}
            onChange={handlePasswordChange}
          />
          <Button type="submit" disabled={!email || !password}>
            Log in
          </Button>
        </LoginForm>
        {/* <div className="text-align-center divider">or</div>
        <SsoButtons>
          <GoogleLogin
            onSuccess={credentialResponse => {
              if (!credentialResponse.credential) return message.error('Login failed.');
              handleLoginWithSso(credentialResponse.credential, 'google');
            }}
            onError={() => {
              console.log('Login SSO Failed');
            }}
            auto_select={false}
            width={328}
            text="continue_with"
            logo_alignment="center"
            size="large"
            shape="square"
            ux_mode="popup"
            theme="outline"
            locale="en_Us"
          />
          <AppleSignInButton handleLogin={handleLoginWithSso} />
        </SsoButtons> */}
      </Spin>
      <TermsOfUsePrivacyPolicy />
    </LoginWrapper>
  );
};

export default LoginPage;
