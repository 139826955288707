import { useCallback, useState } from 'react';
import { getCurrentVersion, getWebConfig, updateCurrentVersion } from '../api/apiWorker';
import { message } from 'antd';
import { ChangeWebAppVersionsFormValues, IWebConfig } from '../types';

export const useGetCurrentVersion = () => {
  const [loading, setLoading] = useState(false);
  const [loadingWebConfig, setLoadingWebConfig] = useState(false);
  const [currentVersion, setCurrentVersion] = useState('');
  const [currentWebConfig, setCurrentWebConfig] = useState<IWebConfig | null>(null);

  const fetchCurrentVersion = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getCurrentVersion();
      setCurrentVersion(result);
    } catch (e) {
      message.error('Unable to fetch version');
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchWebbAppVersions = useCallback(async () => {
    setLoadingWebConfig(true);
    try {
      const result = await getWebConfig();
      result && setCurrentWebConfig(result);
    } catch (e) {
      message.error('Unable to fetch web config');
      console.log(e);
    } finally {
      setLoadingWebConfig(false);
    }
  }, []);

  const updateClientVersion = useCallback(async (newVersion: string) => {
    setLoading(true);
    try {
      await updateCurrentVersion({ configId: 'client_version', newConfig: newVersion });
      setCurrentVersion(newVersion);
      message.success('Version was successfully updated');
    } catch (error) {
      message.error('Unable to update version');
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const updateWebAppConfig = useCallback(
    async ({
      configId,
      newConfig
    }: {
      configId: 'builds_download_urls' | 'goiteens_builds_download_urls';
      newConfig: ChangeWebAppVersionsFormValues;
    }) => {
      setLoading(true);
      try {
        await updateCurrentVersion({ configId: configId, newConfig: newConfig });
        setCurrentWebConfig(
          prev =>
            ({
              ...prev,
              [configId]: newConfig[configId]
            }) as IWebConfig
        );
        message.success('Version was successfully updated');
      } catch (error) {
        message.error('Unable to update version');
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    fetchCurrentVersion,
    updateClientVersion,
    currentVersion,
    loading,
    loadingWebConfig,
    currentWebConfig,
    fetchWebbAppVersions,
    updateWebAppConfig
  };
};
