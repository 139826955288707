import axios from 'axios';
import {
  Account,
  AuthResponse,
  IActionsConfig,
  IBaseConfigs,
  IChangeCharactersData,
  ICharacters,
  IUploadData,
  IUserEdit,
  IWebConfig,
  LiveObjects,
  Organization,
  OrganizationItem,
  OrganizationUsers,
  Providers,
  RegistrationFormValues,
  Resource,
  ResourceDetail,
  ResourceCreatePayload,
  ScriptCreatePayload,
  ScriptDuplicatePayload,
  ScriptFormValues,
  IMoveScriptToFolderError,
  IMoveScriptsData,
  ChangeWebAppVersionsFormValues
} from '../types';
import { getStoredAuthData } from '../context/AuthContext';
import { IDeleteResource } from '../types';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + 'admin'
});

const apiWithoutAdmin = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const appendAuthHeader = () => {
  const authData = getStoredAuthData();
  if (authData && authData.token) {
    api.defaults.headers.common['Authorization'] = authData.token;
  }
};

api.interceptors.response.use(
  response => response, // Simply return for successful responses
  error => {
    console.error('API Error:', error);
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access
    } else if (!error.response) {
      // Handle network errors or CORS issues where the response is undefined
      console.error('Network error or no CORS headers present');
    }
    return Promise.reject(error);
  }
);

export const authHandler = async (
  email: string,
  password: string,
  ssoToken?: string,
  provider?: Providers
): Promise<AuthResponse> => {
  try {
    const token = btoa(email + '|MP|' + password);
    const postData = {
      provider: provider || 'mapanda',
      token: ssoToken || token
    };
    const response = await api.post<AuthResponse>('/authorization', postData);
    return response.data;
  } catch (error) {
    console.error('Login Error:', error);
    throw error;
  }
};

export const refreshTokenHandler = async (token: string): Promise<AuthResponse> => {
  appendAuthHeader();

  try {
    const response = await api.post<AuthResponse>('/authorization/refresh', { token });
    return response.data;
  } catch (error) {
    console.log('Token refresh error:', error);
    throw error;
  }
};

export const registrationHandler = async (
  data: Pick<RegistrationFormValues, 'email' | 'role' | 'organizations'>
): Promise<Account> => {
  appendAuthHeader();
  try {
    const response = await api.post<Account>('/users', data);
    return response.data;
  } catch (error) {
    console.error('Registration Error:', error);
    throw error;
  }
};

export const getWebConfig = async () => {
  try {
    const res = await apiWithoutAdmin.get<IWebConfig>('/web-public-config');
    if (res.data) {
      return res.data;
    }
  } catch (e) {
    console.error(e, 'get web config');
  }
};

export const getAccounts = async () => {
  appendAuthHeader();
  try {
    return await api.get<Account[]>('/users');
  } catch (error) {
    console.error('Fetch Accounts Error:', error);
    throw error;
  }
};

export const deleteAccount = async (id: string, status: string) => {
  appendAuthHeader();
  try {
    return await api.delete(`/users/${id}`, { data: { status } });
  } catch (error) {
    console.error('Delete Account Error:', error);
    throw error;
  }
};

export const updateAccount = async (id: string, data: IUserEdit) => {
  appendAuthHeader();

  try {
    return await api.patch(`/users/${id}`, data);
  } catch (error) {
    console.error('Update Account Error:', error);
    throw error;
  }
};

export const changeStatus = async (id: string, status: string) => {
  appendAuthHeader();
  try {
    const response = await api.patch(`/users/${id}`, { status });
    return response;
  } catch (error) {
    console.error('Change Status Error:', error);
    throw error;
  }
};

export const changePassword = async (id: string) => {
  appendAuthHeader();
  try {
    const response = await api.post<{ password: string }>(`/users/${id}/regenerate-password`);
    return response.data;
  } catch (error) {
    console.error('Change Password Error:', error);
    throw error;
  }
};

export const getResources = async (queryParams?: string) => {
  appendAuthHeader();
  try {
    const response = await api.get<Resource[]>(`/resources/${queryParams || ''}`);
    return response.data;
  } catch (error) {
    console.error('Fetch scripts error:', error);
    throw error;
  }
};

export const getResource = async (resourceId: string, quryParam?: string) => {
  appendAuthHeader();
  try {
    const response = await api.get<ResourceDetail>(`/resources/${resourceId}/${quryParam || ''}`);
    return response.data;
  } catch (error) {
    console.error('Fetch script error:', error);
    throw error;
  }
};

export const moveScripstToFolder = async ({ folderId, scripts, force_parent_access }: IMoveScriptsData) => {
  appendAuthHeader();
  try {
    return await api.post(`/resources/move`, {
      move_to_id: folderId,
      resource_ids: scripts,
      ...(force_parent_access ? { force_parent_access: true } : {})
    });
  } catch (error) {
    console.error('Move scripts to folder error:', error);
    throw error;
  }
};

// export const getScript = async (scriptId: string) => {
//   appendAuthHeader();
//   try {
//     const response = await api.get<Script>(`/scripts/${scriptId}`);
//     return response.data;
//   } catch (error) {
//     console.error('Fetch script error:', error);
//     throw error;
//   }
// };

export const uploadScript = async (formData: FormData, onProgress: (percent: number) => void) => {
  appendAuthHeader();

  try {
    return await api.post<string[]>('/scripts/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progressEvent => {
        if (progressEvent.total == null) return;
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted);
      }
    });
  } catch (error) {
    console.error('Upload Script Error:', error);
    throw error;
  }
};

export const getLargeFileUploadData = async (): Promise<IUploadData | undefined> => {
  appendAuthHeader();

  try {
    const response = await api.get<IUploadData>('/scripts/upload');
    return response.data;
  } catch (e) {
    console.log('Error getting upload data for large files:', e);
    throw e;
  }
};

export const fetchSlidesForUpload = async (uploadId: string): Promise<ScriptFormValues | undefined> => {
  appendAuthHeader();
  if (!uploadId) return;

  try {
    const response = await api.get<ScriptFormValues>(`uploads/${uploadId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching slides for upload:', error);
    throw error;
  }
};

export const uploadFileToS3 = async (url: string, formData: FormData) => {
  if (!url || !formData) {
    throw new Error('upload failed');
  }
  const response = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response;
};

export const createResource = async (data: ResourceCreatePayload) => {
  appendAuthHeader();
  try {
    return await api.post('/resources', data);
  } catch (error) {
    console.error('Create Script Error:', error);
    throw error;
  }
};

export const createScript = async (data: ScriptCreatePayload) => {
  appendAuthHeader();
  try {
    return await api.post('/scripts', data);
  } catch (error) {
    console.error('Create Script Error:', error);
    throw error;
  }
};

export const updateResource = async (data: ResourceCreatePayload) => {
  appendAuthHeader();
  const { resource_id, ...restData } = data;
  try {
    return await api.patch(`/resources/${resource_id}`, restData);
  } catch (error) {
    console.error('Update Script Error:', error);
    throw error;
  }
};

// export const updateScript = async (data: ScriptCreatePayload) => {
//   appendAuthHeader();
//   const { script_id, ...restData } = data;
//   try {
//     return await api.patch(`/scripts/${script_id}`, restData);
//   } catch (error) {
//     console.error('Update Script Error:', error);
//     throw error;
//   }
// };

export const duplicateScript = async (data: ScriptDuplicatePayload) => {
  appendAuthHeader();
  try {
    return await api.post(`/resources/duplicate`, { ...data });
  } catch (error) {
    console.error('Duplicate Script Error:', error);
    throw error;
  }
};

export const deleteResource = async ({ resourceId, deleteChildResources, resourceType }: IDeleteResource) => {
  appendAuthHeader();
  const params =
    deleteChildResources !== undefined && resourceType === 'folder'
      ? { delete_child_resources: deleteChildResources }
      : undefined;
  try {
    return await api.delete(`/resources/${resourceId}`, { params });
  } catch (error) {
    console.error('Delete Script Error:', error);
    throw error;
  }
};

// export const deleteScript = async (scriptId: string) => {
//   appendAuthHeader();

//   try {
//     return await api.delete(`/scripts/${scriptId}`);
//   } catch (error) {
//     console.error('Delete Script Error:', error);
//     throw error;
//   }
// };

// Organizations

export const getOrganizations = async () => {
  appendAuthHeader();
  try {
    const response = await api.get<OrganizationItem[]>('/organizations');
    return response.data;
  } catch (error) {
    console.error('Fetch organizations error:', error);
    throw error;
  }
};

export const getOrganizationUsers = async (organizationId: string) => {
  appendAuthHeader();

  try {
    const response = await api.get<OrganizationUsers>(`/organizations/${organizationId}/users`);
    return response.data;
  } catch (error) {
    console.error('Fetch organization users error:', error);
    throw error;
  }
};

export const createOrganization = async (data: Organization) => {
  appendAuthHeader();

  try {
    return await api.post('/organizations', data);
  } catch (error) {
    console.error('Create Organization Error:', error);
    throw error;
  }
};

export const updateOrganization = async (data: Organization) => {
  appendAuthHeader();
  const { organization_id, ...restData } = data;

  try {
    return await api.patch(`/organizations/${organization_id}`, restData);
  } catch (error) {
    console.error('Update Organization Error:', error);
    throw error;
  }
};

export const deleteOrganization = async (organizationId: string) => {
  appendAuthHeader();

  try {
    return await api.delete(`/organizations/${organizationId}`);
  } catch (error) {
    console.error('Delete organization Error:', error);
    throw error;
  }
};

// Live Objects
export const getLiveObjects = async () => {
  appendAuthHeader();

  try {
    const response = await api.get<LiveObjects>(`/live-objects`);
    return response.data;
  } catch (error) {
    console.error('Fetch live objects error:', error);
    throw error;
  }
};

export const getActionsConfig = async () => {
  appendAuthHeader();
  try {
    const { data } = await api.get<IActionsConfig>('/configs/script_actions_config');
    return data;
  } catch (error) {
    console.error('Fetch Actions error:', error);
  }
};

export const getCurrentVersion = async () => {
  appendAuthHeader();

  try {
    const response = await api.get('/configs/client_version');
    return response.data;
  } catch (error) {
    console.error('fetch Current Version error');
    throw error;
  }
};

export const getBaseConfigs = async () => {
  appendAuthHeader();

  try {
    const { data } = await api.get<IBaseConfigs>('/configs/base');
    return data;
  } catch (error) {
    console.error('Fetch Actions error:', error);
  }
};

export const updateCurrentVersion = async ({
  configId,
  newConfig
}: {
  configId: 'client_version' | 'builds_download_urls' | 'goiteens_builds_download_urls';
  newConfig: string | ChangeWebAppVersionsFormValues;
}) => {
  appendAuthHeader();
  const payload =
    typeof newConfig === 'string'
      ? { value: newConfig }
      : {
          value: newConfig[configId as 'builds_download_urls' | 'goiteens_builds_download_urls']
        };
  try {
    await api.put(`/configs/${configId}`, payload);
  } catch (error) {
    console.error('fetch Current Version error');
    throw error;
  }
};

export const getCharacters = async () => {
  appendAuthHeader();

  try {
    const { data } = await api.get<ICharacters[]>('/characters');
    return data;
  } catch (error) {
    console.error('fetch Current Version error');
    throw error;
  }
};

export const changeCharactersAvailability = async (id: string, data: IChangeCharactersData) => {
  appendAuthHeader();
  try {
    await api.patch(`/characters/${id}`, data);
  } catch (error) {
    console.error('fetch Current Version error');
    throw error;
  }
};
