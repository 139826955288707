import { useCallback, useEffect } from 'react';
import { initialScriptFormValues, useScriptForm } from '../ScriptCreateStore';
import { getResource } from '../../../api/apiWorker';
import { useAuth } from '../../../context/AuthContext';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { usePreview } from '../../../context/PreviewContext';
import { LoItemToPreview, Scenario } from '../../../types';

export const useGetFormData = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const {
    setFormValues,
    setReadOnlyMode,
    setLoading,
    setInitialValues,
    setSharingList,
    // setSelectedUserValues,
    // setSelectedOrganizationsValues,
    sharingList
  } = useScriptForm();

  const { setLoInitialFormValues } = usePreview();
  const transformLoFormValues = useCallback((scenarioState: Scenario[]) => {
    const loItemToPreview: LoItemToPreview = {};

    scenarioState.forEach(chapter => {
      chapter.blocks.forEach(block => {
        const liveObjects = block.actions?.filter(action => action.action_type === 'live_object');
        if (liveObjects && liveObjects.length > 0) {
          loItemToPreview[block.id] = liveObjects.map(action => ({
            live_object_id: action.live_object_id || '',
            dropdownKey: `live_object-${action.group}`,
            uniq_id: action.uniq_id || '',
            position_x: action.position_x,
            position_y: action.position_y,
            size: action.size,
            action_type: action.id === 'delete_all_live_objects' ? action.id : action.action_type
          }));
        }
      });
    });

    return loItemToPreview;
  }, []);
  const fetchScript = useCallback(
    async (scriptId: string) => {
      setLoading(true);
      try {
        const resourceData = await getResource(scriptId);
        const { slides, users, scenario, background, access_control, ...restData } = resourceData;
        if (users?.some(u => u.user_id === user?.user.user_id)) {
          setReadOnlyMode(false);
        }
        const usersAccess = access_control?.filter(control => control.entity_type === 'user');
        const organizationsAccess = access_control?.filter(control => control.entity_type === 'organization');

        const organizationItems =
          organizationsAccess?.map(org => ({
            value: org.entity_id,
            label: org.name || '',
            restriction: org.write_access ? 'edit' : 'view'
          })) ?? [];
        const userItems =
          usersAccess?.map(user => ({
            value: user.entity_id,
            label: user.name || '',
            restriction: user.write_access ? 'edit' : 'view'
          })) ?? [];
        const scenarioItems = scenario.map(_scenario => {
          const blocksWithId = _scenario.blocks.map(block => ({
            ...block,
            actions:
              block.actions?.map(action => {
                if (action.action_type === 'delete_all_live_objects') {
                  return {
                    action_type: 'live_object',
                    id: action.id,
                    group: action.group
                  };
                } else {
                  return action;
                }
              }) || [],
            id: block.id || uuidv4()
          }));
          return {
            ..._scenario,
            blocks: blocksWithId,
            id: _scenario.id || uuidv4()
          };
        });
        const initialLoFormValues = transformLoFormValues(scenarioItems);
        const formValues = {
          ...restData,
          slides,
          organizations: [],
          users: [],
          scenario: scenarioItems,
          background: background || ''
        };
        const initialFormValues = {
          ...restData,
          slides,
          organizations: organizationItems,
          users: userItems,
          scenario: scenarioItems,
          background: background || ''
        };
        setSharingList({ ...sharingList, users: userItems, organizations: organizationItems });
        setFormValues({ ...initialScriptFormValues, ...formValues });
        setInitialValues({ ...initialScriptFormValues, ...initialFormValues });
        setLoInitialFormValues(initialLoFormValues);
      } catch (e) {
        message.error('Unable to fetch script');
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [user, setFormValues, setLoading, setReadOnlyMode, setInitialValues]
  );

  useEffect(() => {
    id && fetchScript(id);
  }, [id, fetchScript]);
};
