import React, { useEffect, useMemo, useState } from 'react';
import { message, Space, TableProps, Typography } from 'antd';
import CloseIcon from '../../../assets/close.png';
import { ButtonSubmit, PopUpWrapper, StyledPopUpContent, StyledPopupHeader } from '../ScriptStyles';
import { FilterOptions, IMoveScriptsData, Resource, SortOptions } from '../../../types';
import { ScriptsTable } from '../ScriptsTable';
import { OrganizationTags } from '../../../components/OrganizationTags';
import { BackArrowIcon, FileIcon, FolderIcon } from '../../../assets/icons';
import { FilterPanel } from '../FilterPanel';
import { extractOrganizationsFromScripts } from '../../../helper';
import { Filters } from '../ScriptsPage';
import { Search } from '../../../components/Search';
import { FilterPanelItems } from '../scriptPage.styled';
import { getResources } from '../../../api/apiWorker';
import { TableRowSelection } from 'antd/es/table/interface';

import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

interface Props {
  resourceType: 'script' | 'folder';
  selectedResourceId: string;
  setShowPopupSelectScript: React.Dispatch<React.SetStateAction<boolean | null>>;
  handleMoveScripts?: ({ folderId, scripts }: IMoveScriptsData) => Promise<void>;
}

const PopupContent = styled(StyledPopUpContent)`
  max-width: 830px;
`;
const StyledPopUpWrapper = styled(PopUpWrapper)`
  padding: 0 15px;
`;

const PanelWrapper = styled(FilterPanelItems)`
  .filter-panel {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const StyledBackArrowIcon = styled(BackArrowIcon)`
  margin-right: auto;
  cursor: pointer;
`;

export type IRowSelection = TableRowSelection<Resource>;

export const PopupSelectScriptOrFolder: React.FC<Props> = ({
  resourceType,
  selectedResourceId,
  setShowPopupSelectScript,
  handleMoveScripts
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [filters, setFilters] = useState<Filters>({
    search: '',
    filter: { option: FilterOptions.all, selectedOrganizations: null }
  });
  const [sortOption, setSortOption] = useState<SortOptions>(SortOptions.newest);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [allScripts, setAllScripts] = useState<Resource[]>([]);
  const [scriptsTorender, setScriptsToRender] = useState<Resource[]>([]);
  const [scriptAllOrganizations, setScriptAllOrganizations] = useState<string[]>([]);
  const [scriptOrganizationsToRender, setScriptOrganizationsToRender] = useState<string[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<string[]>([]);

  const [isSelectedFolder, setIsSelectedFolder] = useState(false);
  const location = useLocation();

  const fetchResources = async () => {
    setIsLoading(true);
    const params = new URLSearchParams(location.search);
    const parentId = params.get('folderId');
    try {
      const data =
        resourceType === 'folder'
          ? await getResources(`?resource_type=${resourceType}&write_allowed=true`)
          : await getResources(`?write_allowed=true`);
      if (Array.isArray(data)) {
        let resourceData = [];
        if (resourceType === 'script') {
          resourceData = data.filter(resource => resource.resource_id !== selectedResourceId);
        } else if (resourceType === 'folder' && parentId) {
          resourceData = data.filter(resource => resource.resource_id !== parentId);
        } else {
          resourceData = data;
        }
        setAllScripts(resourceData);
        setScriptsToRender(resourceData);
        const organizationList = extractOrganizationsFromScripts(resourceData);
        setScriptAllOrganizations(organizationList);
        setScriptOrganizationsToRender(organizationList);
      } else {
        setAllScripts([]);
        setScriptsToRender([]);
      }
    } catch (e) {
      message.error('Error fetching scripts');
      console.log(e);
    }
    setIsSelectedFolder(false);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchResources();
  }, []);

  const handleBackToList = async () => {
    if (allScripts.length) {
      setScriptsToRender(allScripts);
      setScriptOrganizationsToRender(scriptAllOrganizations);

      return setIsSelectedFolder(false);
    }

    await fetchResources();
  };

  const fetchScriptsinFolder = async (folderId: string) => {
    try {
      setIsLoading(true);
      const data = await getResources(`?parent_id=${folderId}&write_allowed=true`);
      if (Array.isArray(data)) {
        setScriptsToRender(data);
        const organizationList = extractOrganizationsFromScripts(data);
        setScriptOrganizationsToRender(organizationList);
      } else {
        setScriptsToRender([]);
      }
      setIsSelectedFolder(true);
    } catch (e) {
      console.log(e, 'error fetch scripts in folder');
      message.error('Error fetching scripts');
    } finally {
      setIsLoading(false);
    }
  };

  const getResourceIds = (resorceType: 'script' | 'folder'): string[] => {
    return allScripts.filter(script => script.resource_type === resorceType).map(item => item.resource_id);
  };
  const getCurrentResourceIds = (resorceType: 'script' | 'folder'): string[] => {
    return scriptsTorender.filter(script => script.resource_type === resorceType).map(item => item.resource_id);
  };

  const getCurrentScriptsWithParent = (parentId: string): string[] => {
    return scriptsTorender.filter(script => script.parent_id === parentId).map(item => item.resource_id);
  };

  useEffect(() => {
    if (resourceType === 'script') {
      const foldersIds = getResourceIds('folder');
      const filteredFoldersIdsToDeleteFromKeys = foldersIds.filter(id => !selectedFolder.includes(id));
      setSelectedRowKeys(prevKeys => {
        const selectedSet = new Set(prevKeys);

        selectedFolder.forEach(id => {
          selectedSet.add(id);
        });

        const updatedKeys = Array.from(selectedSet).filter(
          id => selectedFolder.includes(id as string) || !filteredFoldersIdsToDeleteFromKeys.includes(id as string)
        );
        return updatedKeys;
      });
    }
  }, [selectedFolder]);

  const handleRowDoubleClick = (record: Resource) => {
    const folderId = record.resource_id;
    fetchScriptsinFolder(folderId);
    return;
  };

  const isHasFolderSelectedChildren = (folderId: string, selected: boolean, selectedId: string): boolean => {
    const scriptsParentIds = getCurrentScriptsWithParent(folderId);
    const filteredScriptsBySelected = selected ? scriptsParentIds : scriptsParentIds.filter(item => item !== selectedId);
    return !!selectedRowKeys.filter(key => filteredScriptsBySelected.includes(key as string)).length;
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: Resource[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onSelectAll = (selectedRows: Resource[]) => {
    const renderParentId: string[] = [scriptsTorender[0]?.parent_id].flatMap(id => (id ? [id] : []));
    const selectedRowsValues = selectedRows.map(item => item?.resource_id).filter(Boolean);
    const resourcesTypeScriptsIds = getCurrentResourceIds('script');
    const foldersIds = getResourceIds('folder');
    const scriptswithoutFolder = selectedRowsValues.filter(id => !foldersIds.includes(id as string));
    if (!scriptswithoutFolder.length) {
      const rowsWithSelectedInFoldersScripts = selectedRowKeys.filter(
        scriptId => !resourcesTypeScriptsIds.includes(scriptId as string)
      );
      renderParentId &&
        setSelectedFolder(prev => {
          return prev.filter(item => item !== renderParentId[0]);
        });

      return setSelectedRowKeys(rowsWithSelectedInFoldersScripts);
    }

    const updatedSelectedRowKeys = Array.from(new Set([...selectedRowKeys, ...selectedRowsValues]));
    setSelectedRowKeys(updatedSelectedRowKeys);
    renderParentId && setSelectedFolder(prev => Array.from(new Set([...prev, ...renderParentId])));
  };

  const onSelect = (record: Resource, selected: boolean) => {
    const selectedId = record.resource_id;
    const parentId = record.parent_id || '';
    if (selected) {
      const updatedSelectedFolders = Array.from(new Set([...selectedFolder, parentId]));
      setSelectedRowKeys(prevKeys => [...prevKeys, selectedId]);

      parentId && setSelectedFolder(updatedSelectedFolders);
    } else {
      setSelectedRowKeys(prevKeys => prevKeys.filter(key => key !== selectedId));
      parentId &&
        !isHasFolderSelectedChildren(parentId, selected, selectedId) &&
        setSelectedFolder(prevFolderId => prevFolderId.filter(id => id !== parentId));
    }
  };

  const rowSelection: IRowSelection = {
    type: 'checkbox',
    selectedRowKeys,
    // onChange: onSelectChange,
    onSelect: onSelect,
    onSelectAll: (selected: boolean, selectedRows: Resource[]) => {
      onSelectAll(selectedRows);
    },
    getCheckboxProps: (record: Resource) => {
      return {
        disabled: record.resource_type !== 'script'
        // style: record.resource_type !== 'script' ? { display: 'none' } : {}
      };
    }
    // hideSelectAll: true
  };

  const rowSelectionSingle: IRowSelection = {
    type: 'radio',
    onChange: onSelectChange
  };

  // const dropdownMenu: MenuProps['items'] = [
  //   {
  //     label: <Typography className="normal-16 text-secondary fnt-mulish">Menu-1</Typography>,
  //     key: 'menu-1',
  //     onClick: () => console.log('CLICK MENU-1')
  //   },
  //   {
  //     label: <Typography className="normal-16 text-secondary fnt-mulish">Menu-2</Typography>,
  //     key: 'menu-2',
  //     disabled: false,
  //     onClick: () => console.log('Menu-2')
  //   }
  // ];

  const filteredScripts: Resource[] = useMemo(() => {
    const folders = scriptsTorender.filter(script => script.resource_type === 'folder');
    const scriptFiles = scriptsTorender.filter(script => script.resource_type === 'script');

    let resultFolder = folders.filter(script => script.resource_name.toLowerCase().includes(filters.search.toLowerCase()));
    let resultScripts = scriptFiles.filter(script => script.resource_name.toLowerCase().includes(filters.search.toLowerCase()));

    const { option, selectedOrganizations } = filters.filter;
    if (option === FilterOptions.withoutOrganization) {
      resultFolder = folders.filter(folder => !folder.organization_names?.length);
      resultScripts = scriptFiles.filter(script => !script.organization_names?.length);
    }
    if (selectedOrganizations?.length) {
      resultFolder = resultFolder.filter(folder => {
        return folder.organization_names?.some(org => selectedOrganizations.includes(org));
      });
      resultScripts = resultScripts.filter(script => {
        return script.organization_names?.some(org => selectedOrganizations.includes(org));
      });
    }

    return [...resultFolder, ...resultScripts];
  }, [scriptsTorender, filters, sortOption]);

  const handleMoveScriptsToFolder = () => {
    const foldersIds = getResourceIds('folder');
    const scriptsMoveToFolder: string[] = selectedRowKeys.filter(id => !foldersIds.includes(id as string)) as string[];
    if (!handleMoveScripts) return;
    if (resourceType === 'script') {
      handleMoveScripts({ folderId: selectedResourceId, scripts: scriptsMoveToFolder });
    } else {
      handleMoveScripts({ folderId: selectedRowKeys[0] as string, scripts: [selectedResourceId] });
    }
    setShowPopupSelectScript(null);
    console.log(selectedRowKeys, 'MOVE', selectedResourceId);
  };

  const tableColumns: TableProps<Resource>['columns'] = [
    {
      title: 'Type',
      dataIndex: 'resource_type',
      key: 'resource_type',
      className: 'extrabold-14',
      width: '20%',
      render: (resource_type: Resource['resource_type']) =>
        resource_type === 'script' ? (
          <FileIcon style={{ width: '18px', height: '22px', verticalAlign: 'inherit' }} className="hint-color" />
        ) : (
          <FolderIcon style={{ width: '18px', height: '22px', verticalAlign: 'inherit' }} />
        )
    },
    {
      title: 'Name',
      dataIndex: 'resource_name',
      key: 'resource_name',
      className: 'extrabold-14',
      width: '30%',
      ellipsis: true
    },

    {
      title: 'Organization',
      dataIndex: 'organization_names',
      key: 'organization_names',
      width: '40%',
      render: (organizations: string[]) => <OrganizationTags organizations={organizations} />
    }
  ];

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <StyledPopUpWrapper onDoubleClick={stopPropagation}>
      <PopupContent>
        <StyledPopupHeader>
          <Typography.Text className="text-title fnt-open-sans" style={{ fontSize: '24px', fontWeight: '700' }}>
            Select Scripts
          </Typography.Text>

          <div className="close" onClick={() => setShowPopupSelectScript(null)}>
            <img src={CloseIcon} alt={''} />
          </div>
        </StyledPopupHeader>
        <PanelWrapper>
          {isSelectedFolder && <StyledBackArrowIcon onClick={handleBackToList} />}

          <FilterPanel
            organizations={scriptOrganizationsToRender}
            onSort={opt => setSortOption(opt)}
            onFilterChange={filter => setFilters(prev => ({ ...prev, filter }))}
            isShowSortPanel={false}
          />
          <Search
            className={`${toggleSearch ? 'search-visible' : 'search-hide'}`}
            placeholder=""
            onChange={search => setFilters(prev => ({ ...prev, search }))}
            setToggleSearch={setToggleSearch}
          />
        </PanelWrapper>
        <>
          <ScriptsTable
            tableData={filteredScripts}
            loading={isLoading}
            tableColumnsProps={tableColumns}
            rowSelection={resourceType === 'folder' ? rowSelectionSingle : rowSelection}
            handleRowDoubleClick={handleRowDoubleClick}
          />
        </>
        <ButtonSubmit type="button" disabled={!selectedRowKeys.length} onClick={handleMoveScriptsToFolder}>
          <Space> Move to folder</Space>
        </ButtonSubmit>
      </PopupContent>
    </StyledPopUpWrapper>
  );
};
