import React, { useEffect } from 'react';
import { PageContainer, PageWrapper } from '../../assets/styles/Styled';
import { PageContent } from '../LandingPage/styled';
import { Spin, Typography } from 'antd';
import { ChangeVersionForm } from './ChangeVersionForm';
import styled from 'styled-components';
import { useGetCurrentVersion } from '../../hooks/useGetCurrentVersion';
import { ChangeWebAppVersionForm } from './ChangeWebAppForm';
import { ChangeWebAppVersionsFormValues, IWebConfigN } from '../../types';

const ChangeVersionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  background: #ffffff;
  height: 100%;
  width: 100%;
  max-width: 80%;
  padding: 12px;
  margin: 0 auto;
`;

export const HeaderText = styled(Typography)`
  margin: 16px 0 32px 0;
`;

const WebAppFormWrapper = styled.div`
  margin: 40px 0 20px;
  text-align: center;
`;

const SettingsPage = styled(PageWrapper)`
  overflow-y: auto;
`;

const SettingsPageContainer = styled(PageContainer)`
  min-height: fit-content;
  height: fit-content;
`;

const ChangeVersion = () => {
  const {
    loading,
    fetchCurrentVersion,
    currentVersion,
    updateClientVersion,
    currentWebConfig,
    fetchWebbAppVersions,
    loadingWebConfig,
    updateWebAppConfig
  } = useGetCurrentVersion();
  useEffect(() => {
    fetchCurrentVersion();
    fetchWebbAppVersions();
  }, []);

  return (
    <SettingsPage>
      <SettingsPageContainer>
        <PageContent>
          <ChangeVersionWrapper>
            <HeaderText className="bold-24 text-basic">Client Version</HeaderText>
            <Spin
              wrapperClassName="spin-full"
              spinning={loading && loadingWebConfig}
              className="w-100"
              style={{ minWidth: '100% !important', height: '100%' }}
            >
              <ChangeVersionForm loading={loading} currentVersion={currentVersion} updateVersion={updateClientVersion} />
              <WebAppFormWrapper>
                <HeaderText className="bold-24 text-basic">Web App Config</HeaderText>

                <ChangeWebAppVersionForm
                  updateWebAppConfig={updateWebAppConfig}
                  builds_download_urls={currentWebConfig?.builds_download_urls as IWebConfigN}
                  goiteens_builds_download_urls={currentWebConfig?.goiteens_builds_download_urls as IWebConfigN}
                />
              </WebAppFormWrapper>
            </Spin>
          </ChangeVersionWrapper>
        </PageContent>
      </SettingsPageContainer>
    </SettingsPage>
  );
};

export default ChangeVersion;
